.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  cursor: pointer;

  .iconArea {
    background-color: white;
    border-radius: 50%;
    transition: background-color 0.5s;
  }

  .icon {
    color: #f8aba8;
    padding: 20px;
    transition: color 0.5s;
  }

  &:hover, &.active {
    .iconArea {
      background-color: #f8aba8;
    }

    .icon {
      color: white;
    }
  }
}
