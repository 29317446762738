.root {
  order: 1;
  flex-shrink: 0;
  width: 400px;
  padding: 0 20px 20px;
  height: 100vh;
  overflow: auto;
  border-left: 1px solid #f8aba8;
}

.contentImageWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 30px;

  &:after {
    content: "";
    width: 30%;
  }
}

.contentImage {
  width: 100%;
}

.styleImageWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 30px;

  &:after {
    content: "";
    width: 30%;
  }
}

.imageFrame {
  width: 30%;
  margin-bottom: 20px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;

  .styleImage,
  .contentImage {
    width: 100%;
    border-radius: 5px;
    transition: transform 0.3s, box-shadow 0.3s;
  }

  &:hover {
    .styleImage,
    .contentImage {
      transform: scale(1.1);
      box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5);
    }
  }
}

.loader {
  align-self: center;
}

.header {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  color: #f8aba8;
  font-size: 24px;
  font-weight: 800;
  border-bottom: 1px solid #f8aba8;
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 1;
}

.formField {
  margin-top: 20px;
}

.formLabel {
  margin-bottom: 5px;
  color: #666;
}

.composerSelector {
  cursor: pointer;
}

.scaleBarField {
  width: 95%;
}

.uploadContentImageForm {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;

  .uploadContentImageLabel {
    cursor: pointer;
  }
  .uploadContentImageInput {
    display: none;
  }
}
