.root {
  order: 0;
  flex-grow: 1;
  padding: 50px;
}

.actionBar {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 20px;
}

.imageContainer {
  display: flex;
  justify-content: space-between;
}

.styleImageFrame {
  position: relative;
}

.styleImage {
  width: 100%;
}

.contentImage {
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.result,
.editor {
  width: 45%;
}

.title {
  color: #f8aba8;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}

.composedImage {
  width: 100%;
}
